import React, { useEffect } from 'react';
import Card from '../Card';
import FunFact from '../FunFact';
import Hero from '../Hero';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import Cta from '../Cta';
import LogoList from '../LogoList';
import MovingText from '../MovingText';
import PortfolioSlider from '../Slider/PortfolioSlider';
import PostSlider from '../Slider/PostSlider';
import TestimonialSlider from '../Slider/TestimonialSlider';
import TeamSlider from '../Slider/TeamSlider';
import VideoModal from '../VideoModal';
import TimelineSlider from '../Slider/TimelineSlider';
import { pageTitle } from '../../helper';

// Links sociais do Hero
const heroSocialLinks = [
  {
    name: 'Linkedin',
    links: 'https://linkedin.com/company/innov4r',
  },
  {
    name: 'Instagram',
    links: 'https://www.instagram.com/innov4r.tecnologia/',
  },
];

// Dados do FunFact
const funfaceData = [
  {
    title: 'Clientes Satisfeitos Globalmente',
    factNumber: '40K',
  },
  {
    title: 'Projetos Completados',
    factNumber: '50K',
  },
  {
    title: 'Membros da Equipe',
    factNumber: '245',
  },
  {
    title: 'Produtos Digitais',
    factNumber: '550',
  },
];

const portfolioData = [
  {
    title: 'Trabalho Artístico Colorido',
    subtitle: 'Ver Detalhes',
    href: '/portfolio/portfolio-details',
    src: '/images/portfolio_1.jpeg',
  },
  {
    title: 'Trabalho Artístico Colorido',
    subtitle: 'Ver Detalhes',
    href: '/portfolio/portfolio-details',
    src: '/images/portfolio_2.jpeg',
  },
  {
    title: 'Trabalho Artístico Colorido',
    subtitle: 'Ver Detalhes',
    href: '/portfolio/portfolio-details',
    src: '/images/portfolio_0.jpg',
  },
  {
    title: 'Trabalho Artístico Colorido',
    subtitle: 'Ver Detalhes',
    href: '/portfolio/portfolio-details',
    src: '/images/portfolio_3.jpeg',
  },
];

export default function Home() {
  pageTitle('Início');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* Início da Seção Hero */}
      <Hero
        title="Transformando Visões em Resultados Lucrativos"
        subtitle="Estamos aqui para entregar não só o produto ideal, mas o impacto que você precisa no mercado atual e futuro."
        btnText="Solicite uma Consultoria Exclusiva"
        btnLink="/contact"
        scrollDownId="#service"
        socialLinksHeading="Siga-nos"
        heroSocialLinks={heroSocialLinks}
        bgImageUrl="/images/hero_bg.jpeg"
      />
      {/* Fim da Seção Hero */}

      {/* Início da Seção FunFact */}
      <div className="container">
        <FunFact
          variant="cs-type1"
          title="Resultados que Falam por Si"
          subtitle="Cada número reflete nossa dedicação em entregar excelência. São histórias de sucesso que ajudaram nossos clientes a dominar seus mercados, inovar e crescer exponencialmente. Quer fazer parte dessa história?"
          data={funfaceData}
        />
      </div>
      {/* Fim da Seção FunFact */}

      {/* Início da Seção de Serviços */}
      <Spacing lg="150" md="80" />
      <Div id="service">
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="Soluções Inovadoras para Aumentar seu Lucro"
                subtitle="Nossos Serviços"
                description="Nossa expertise em SaaS permite criar soluções que transformam desafios complexos em oportunidades lucrativas. Desde a concepção até a execução, garantimos resultados que alavancam seu negócio."
                btnText="Explore Nossas Soluções Customizadas"
                btnLink="/service"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-8">
              <Div className="row">
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Design UI/UX"
                    link="/service/service-details"
                    src="/images/service_1.jpeg"
                    alt="Serviço"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Desenvolvimento de software"
                    link="/service/service-details"
                    src="/images/service_2.jpeg"
                    alt="Serviço"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Marketing Digital"
                    link="/service/service-details"
                    src="/images/service_3.jpeg"
                    alt="Serviço"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Tecnologia"
                    link="/service/service-details"
                    src="/images/service_4.jpeg"
                    alt="Serviço"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* Fim da Seção de Serviços */}

      {/* Início da Seção Portfolio */}
      {/* <Spacing lg="150" md="50" /> */}
      {/* <Div>
        <Div className="container">
          <SectionHeading
            title="Projetos que Elevam Padrões"
            subtitle="Transformações Recentes"
            description="Veja como nossas soluções revolucionaram empresas de alto padrão, criando valor duradouro e resultados tangíveis. Cada projeto é um testemunho de como podemos elevar sua empresa ao próximo nível."
            variant="cs-style1 text-center"
          />
          <Spacing lg="90" md="45" />
        </Div>
        <PortfolioSlider data={portfolioData} />
      </Div> */}
      {/* Fim da Seção Portfolio */}

      {/* Início da Seção de Prêmios */}
      <Spacing lg="150" md="80" />
      <Div className="cs-shape_wrap_2">
        <Div className="cs-shape_2">
          <Div />
        </Div>
        {/* <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="Reconhecimento pela Excelência"
                subtitle="Prêmios e Conquistas"
                description="Nossos prêmios são mais do que troféus — são a prova de que entregamos resultados extraordinários. Esses reconhecimentos refletem a confiança que grandes empresas depositaram em nós para superar expectativas."
                variant="cs-style1"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-7 offset-xl-1">
              <TimelineSlider />
            </Div>
          </Div>
        </Div> */}
      </Div>
      {/* Fim da Seção de Prêmios */}

      {/* Início da Seção de Bloco de Vídeo */}
      <Spacing lg="130" md="70" />
      <Div className="container">
        <h2 className="cs-font_50 cs-m0 text-center cs-line_height_4">
          A agilidade é o coração da inovação. Em um mercado onde mudanças são constantes, ajudamos sua empresa a ver essas transformações como alavancas para o sucesso. Assista ao vídeo e descubra como nossas soluções ágeis podem transformar sua visão em realidade.
        </h2>
        <Spacing lg="70" md="70" />
        <VideoModal
          videoSrc="https://www.youtube.com/watch?v=VcaAVWtP48A"
          bgUrl="/images/video_bg.jpeg"
        />
      </Div>
      {/* Fim da Seção de Bloco de Vídeo */}

      {/* Início da Seção Equipe */}
      <Spacing lg="145" md="80" />
      {/* <Div className="container">
        <SectionHeading
          title="Time de Especialistas que Entrega Excelência"
          subtitle="Nossa Equipe de Líderes"
          description="Nosso time é composto por especialistas que não só entendem tecnologia, mas também o impacto dela em negócios. Combinamos expertise técnica com uma profunda compreensão dos desafios empresariais para entregar soluções que realmente fazem a diferença."
          variant="cs-style1"
        />
        <Spacing lg="85" md="45" />
        <TeamSlider />
      </Div>
      <Spacing lg="150" md="80" /> */}
      {/* Fim da Seção Equipe */}

      {/* Início da Seção Testemunhos */}
      <TestimonialSlider />
      {/* Fim da Seção Testemunhos */}

      {/* Início da Seção Blog */}
      <Spacing lg="150" md="80" />
      <Div className="cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="Insights de Mercado para Decisões Inteligentes"
                subtitle="Nosso Blog"
                description="Fique à frente com conteúdos que abordam tendências, estratégias e inovações no setor de SaaS. Nossos artigos são pensados para equipá-lo com o conhecimento necessário para tomar decisões que impulsionam seu negócio."
                btnText="Acesse Nossos Insights"
                btnLink="/blog"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-7 offset-xl-1">
              <Div className="cs-half_of_full_width">
                <PostSlider />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* Fim da Seção Blog */}

      {/* Início da Seção de Texto em Movimento */}
      <Spacing lg="125" md="70" />
      <MovingText text="Nossos parceiros respeitados mundialmente" />
      <Spacing lg="105" md="70" />
      {/* Fim da Seção de Texto em Movimento */}

      {/* Início da Seção LogoList */}
      <Div className="container">
        <LogoList />
      </Div>
      <Spacing lg="150" md="80" />
      {/* Fim da Seção LogoList */}

      {/* Início da Seção CTA */}
      <Div className="container">
        <Cta
          title="Pronto para Transformar sua Empresa? Vamos Conversar."
          description="Cada grande parceria começa com uma conversa. Junte-se a nós para discutir como podemos ajudar a elevar seu negócio ao próximo patamar. Estamos prontos para ouvir suas necessidades e criar soluções que realmente impactam."
          btnText="Agende sua Consultoria Exclusiva"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* Fim da Seção CTA */}
    </>
  );
}

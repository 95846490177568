import React, { useState } from 'react';
import Slider from 'react-slick';
import { Icon } from '@iconify/react';
import Testimonial from '../Testimonial';
import Div from '../Div';
import Spacing from '../Spacing';
const testimonialData = [
  {
    testimonialThumb: '/images/testimonial_5.png',
    testimonialText:
      'A decisão de contratar esta agência foi a melhor escolha para o nosso negócio. A equipe é extremamente competente e transformou nossas ideias em uma realidade digital que superou nossas expectativas. É impressionante como eles conseguem unir criatividade e tecnologia de maneira tão eficaz.',
    avatarName: 'Ana Beatriz',
    avatarDesignation: 'CEO da Innovatech',
    ratings: '5',
  },
  {
    testimonialThumb: '/images/testimonial_2.jpeg',
    testimonialText:
      'Trabalhar com esta empresa foi um divisor de águas para nossa startup. Elas não só entenderam nossa visão, mas também a potencializaram com soluções inovadoras e sob medida. O suporte contínuo e a atenção aos detalhes fizeram toda a diferença. Recomendo de olhos fechados!',
    avatarName: 'Mariana Silva',
    avatarDesignation: 'CTO da Startify',
    ratings: '5',
  },
  {
    testimonialThumb: '/images/testimonial_6.png',
    testimonialText:
      'A parceria com esta agência nos deu uma vantagem competitiva no mercado. A equipe é extremamente profissional e ágil, sempre pronta para atender às nossas necessidades e propor novas soluções. Nossa presença online nunca foi tão forte e consistente.',
    avatarName: 'Luísa Fernandes',
    avatarDesignation: 'Diretora de Marketing da DigitalBoost',
    ratings: '4.5',
  },
  {
    testimonialThumb: '/images/testimonial_4.png',
    testimonialText:
      'Antes de trabalhar com esta empresa, enfrentávamos dificuldades em escalar nosso negócio digitalmente. Hoje, graças à expertise delas, estamos crescendo de forma sustentável e com resultados palpáveis. Elas realmente sabem o que fazem!',
    avatarName: 'Juliana Costa',
    avatarDesignation: 'COO da TechWave',
    ratings: '5',
  },
];


export default function TestimonialSlider() {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-prev slick-arrow' + (currentSlide === 0 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
    >
      <Icon icon="bi:arrow-left" />
    </div>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-next slick-arrow' +
        (currentSlide === slideCount - 1 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
    >
      <Icon icon="bi:arrow-right" />
    </div>
  );
  return (
    <>
      <Div className="cs-gradient_bg_1 cs-shape_wrap_3 cs-parallax">
        <Spacing lg="130" md="80" />
        <Div className="cs-shape_3 cs-to_up">
          <img src="/images/shape_1.svg" alt="Shape" />
        </Div>
        <Div className="container">
          <Div className="cs-testimonial_slider">
            <Div className="cs-testimonial_slider_left">
              <Slider
                asNavFor={nav1}
                ref={slider2 => setNav2(slider2)}
                slidesToShow={3}
                swipeToSlide={true}
                focusOnSelect={true}
                centerMode={true}
                centerPadding="0px"
                arrows={false}
              >
                {testimonialData.map((item, index) => (
                  <Div className="slider-nav_item" key={index}>
                    <Div className="cs-rotate_img">
                      <Div className="cs-rotate_img_in">
                        <img src={item.testimonialThumb} alt="Thumb" />
                      </Div>
                    </Div>
                  </Div>
                ))}
              </Slider>
            </Div>
            <Div className="cs-testimonial_slider_right">
              <Slider
                asNavFor={nav2}
                ref={slider1 => setNav1(slider1)}
                prevArrow={<SlickArrowLeft />}
                nextArrow={<SlickArrowRight />}
                className="cs-arrow_style1"
              >
                {testimonialData.map((item, index) => (
                  <Div key={index}>
                    <Testimonial
                      testimonialText={item.testimonialText}
                      avatarName={item.avatarName}
                      avatarDesignation={item.avatarDesignation}
                      ratings={item.ratings}
                    />
                  </Div>
                ))}
              </Slider>
            </Div>
          </Div>
        </Div>
        <Spacing lg="130" md="80" />
      </Div>
    </>
  );
}

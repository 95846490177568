import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import { pageTitle } from '../../helper';
import Div from '../Div';
import PageHeading from '../PageHeading';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import ContactInfoWidget from '../Widget/ContactInfoWidget';
import emailjs from 'emailjs-com';
import InputMask from 'react-input-mask';

export default function ContactPage() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    companyType: '',
    companySize: '',
    message: ''
  });
  const [isSending, setIsSending] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');

  // Define o título da página de contato
  pageTitle('Nos contate');

  // Faz o scroll até o topo da página quando o componente é montado
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleCompanyTypeChange = (e) => {
    const value = e.target.value;
    setFormData(prevState => ({
      ...prevState,
      companyType: value,
      companySize: value === 'nao-empresa' ? '' : prevState.companySize
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSending(true);

    emailjs.sendForm('service_hqnxxbl', 'template_cdkdv91', e.target, 'cgijsEv7JV4hFt14b')
      .then((result) => {
        setResponseMessage('Mensagem enviada com sucesso!');
        setFormData({
          name: '',
          email: '',
          phone: '',
          companyType: '',
          companySize: '',
          message: ''
        });
        setIsSending(false);
      }, (error) => {
        setResponseMessage('Ocorreu um erro ao enviar a mensagem.');
        setIsSending(false);
      });
  };

  return (
    <>
      <PageHeading
        title="Nos contate"
        bgSrc="/images/contact_hero_bg.jpeg"
        pageLinkText="Nos contate"
      />
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-6">
            <SectionHeading
              title="Tem um projeto em mente?"
              subtitle="Fale conosco"
            />
            <Spacing lg="55" md="30" />
            <ContactInfoWidget withIcon />
            <Spacing lg="0" md="50" />
          </Div>
          <Div className="col-lg-6">
            <form onSubmit={handleSubmit} className="contact-form">
              <Div className="form-group">
                <label htmlFor="name">Nome</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="form-control"
                  required
                />
              </Div>
              <Div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="form-control"
                  required
                />
              </Div>
              <Div className="form-group">
                <label htmlFor="phone">Telefone</label>
                <InputMask
                  mask="(99) 99999-9999"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="form-control"
                  required
                />
              </Div>
              <Div className="form-group">
                <label htmlFor="companyType">Tipo de Empresa</label>
                <select
                  id="companyType"
                  name="companyType"
                  value={formData.companyType}
                  onChange={handleCompanyTypeChange}
                  className="form-control"
                  required
                >
                  <option value="">Selecione...</option>
                  <option value="empresa">Empresa</option>
                  <option value="nao-empresa">Não é uma empresa</option>
                </select>
              </Div>
              {formData.companyType === 'empresa' && (
                <Div className="form-group">
                  <label htmlFor="companySize">Tamanho da Empresa</label>
                  <select
                    id="companySize"
                    name="companySize"
                    value={formData.companySize}
                    onChange={handleChange}
                    className="form-control"
                    required
                  >
                    <option value="">Selecione...</option>
                    <option value="pequena">Pequena</option>
                    <option value="media">Média</option>
                    <option value="grande">Grande</option>
                  </select>
                </Div>
              )}
              <Div className="form-group">
                <label htmlFor="message">Mensagem</label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  className="form-control"
                  rows="4"
                  required
                />
              </Div>
              <Div className="form-group">
                <button type="submit" className="cs-btn cs-style1" disabled={isSending}>
                  {isSending ? 'Enviando...' : 'Enviar Mensagem'}
                </button>
              </Div>
              {responseMessage && (
                <Div className="form-group">
                  <p className="response-message">{responseMessage}</p>
                </Div>
              )}
            </form>
          </Div>
        </Div>
      </Div>
    </>
  );
}

import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import FunFact from '../FunFact';
import PageHeading from '../PageHeading';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import TeamSlider from '../Slider/TeamSlider';
import Spacing from '../Spacing';

const funfaceData = [
  {
    title: 'Clientes Satisfeitos ao Redor do Mundo',
    factNumber: '40K',
  },
  {
    title: 'Projetos Bem-Sucedidos',
    factNumber: '50K',
  },
  {
    title: 'Especialistas Dedicados',
    factNumber: '245',
  },
  {
    title: 'Soluções Digitais Entregues',
    factNumber: '550',
  },
];

export default function AboutPage() {
  pageTitle('Sobre a Innov4r');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* Início da Seção de Cabeçalho da Página */}
      <PageHeading
        title="Sobre a Innov4r"
        bgSrc="images/about_hero_bg.jpeg"
        pageLinkText="Sobre a Innov4r"
      />
      {/* Fim da Seção de Cabeçalho da Página */}

      {/* Início da Seção Sobre */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-7">
            <SectionHeading
              title="Seu Parceiro Estratégico para Transformação Digital"
              subtitle="Sobre a Innov4r"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
                Na Innov4r, acreditamos que a verdadeira inovação não vem apenas da tecnologia, mas das mentes que a desenvolvem. Somos o parceiro ideal para empresas que buscam não apenas sobreviver, mas prosperar em um mercado cada vez mais competitivo. Nosso diferencial está na entrega de soluções personalizadas que realmente impactam os resultados dos nossos clientes.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-xl-2">
            <img
              src="/images/about_img_1.jpeg"
              alt="Sobre"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
          <Div className="col-lg-7">
            <img
              src="/images/about_img_2.jpeg"
              alt="Sobre"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
          <Div className="col-lg-5">
            <img
              src="/images/about_img_3.jpeg"
              alt="Sobre"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
        </Div>
      </Div>
      <Spacing lg="75" md="55" />
      {/* Fim da Seção Sobre */}

      {/* Início da Seção Fun Facts */}
      <Div className="container">
        <FunFact
          title="Por que nossos clientes confiam na Innov4r?"
          subtitle="Nossos números falam por si. Estes são alguns dos marcos que alcançamos ao longo dos anos, refletindo nosso compromisso com a excelência e a inovação."
          data={funfaceData}
        />
      </Div>
      {/* Fim da Seção Fun Facts */}

      {/* Início da Seção Por Que Escolher */}
      <Spacing lg="100" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <img
                  src="/images/about_img_4.jpeg"
                  alt="Por que escolher a Innov4r"
                  className="w-100 cs-radius_15"
                />
              </Div>
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-xl-5 offset-xl-1 col-lg-6">
            <SectionHeading
              title="Por que Escolher a Innov4r?"
              subtitle="Especialistas em inovação digital"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
                Escolher a Innov4r significa optar por um parceiro que entende suas necessidades e oferece soluções personalizadas para o seu negócio. Nossa equipe, composta por especialistas altamente qualificados, está sempre à frente das tendências tecnológicas para garantir que nossos clientes estejam um passo à frente da concorrência.
              </p>
              <Spacing lg="15" md="15" />
              <p className="cs-m0">
                Ao longo dos anos, ajudamos inúmeras empresas a se reinventarem e a alcançarem novos patamares de sucesso. Com a Innov4r, você terá a certeza de estar trabalhando com um time comprometido com o seu crescimento.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
            </SectionHeading>
          </Div>
        </Div>
      </Div>
      {/* Fim da Seção Por Que Escolher */}

      {/* Início da Seção Time */}
      <Spacing lg="145" md="80" />
      {/* <Div className="container">
        <SectionHeading
          title="Conheça nosso <br/>time incrível"
          subtitle="Nosso Time"
          variant="cs-style1"
        />
        <Spacing lg="85" md="45" />
        <TeamSlider />
      </Div> */}
      {/* Fim da Seção Time */}

      {/* Início da Seção CTA */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Vamos transformar sua visão em realidade"
          btnText="Agende uma Reunião"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* Fim da Seção CTA */}
    </>
  );
}
